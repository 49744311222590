

































import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator'
import RouterOption from '@/models/RouterOption'
import WalletMenuItem from '@/components/nav/WalletMenuItem.vue'
import Routes from '@/router/Routes'
import NaviMainWrapper from '@/components/nav/NaviMainWrapper.vue'
import NaviFooterWrapper from '@/components/nav/NaviFooterWrapper.vue'
import NaviHeaderWrapper from '@/components/nav/NaviHeaderWrapper.vue'
import { MemberInfoGateway } from '@/gateway/commons/MemberInfoGateway'

@Component({
  components: {
    NaviHeaderWrapper,
    NaviFooterWrapper,
    NaviMainWrapper,
    WalletMenuItem
  }
})
export default class NaviMenu extends Vue {
  navs: Array<RouterOption> = []
  parentNav?: RouterOption = undefined
  activeNav?: RouterOption = undefined
  footerNav?: RouterOption = undefined
  elMainScrollTop = 0
  withoutNavHeaderPaths = ['']
  isScrolled: boolean = false
  activeChildren: Array<RouterOption> = []

  get isWithoutNavHeader(): boolean {
    const routePath = this.$route.path
    const parentPath = routePath.split('/')[1]
    return this.withoutNavHeaderPaths.includes(parentPath)
  }

  get isDashboard(): boolean {
    return this.$route.path === '/home'
  }

  get greeting(): string {
    const userEmail = MemberInfoGateway.getUserEmail()
    const userName = userEmail.split('@')[0]
    return this.$t('home.hello', { name: userName }) as string
  }

  get isFraudDome(): boolean {
    return this.$route.path.includes('dome')
  }

  @Watch('$route', { immediate: true })
  setRoutePath(route: any): void {
    this.activeNav = route
    this.activeChildren = route.children
    this.setNavs()
  }

  setNavs(): void {
    this.navs = Routes.filter(route => {
      const parentPath = this.$route.path.split('/')[1]
      const isParentPath = parentPath === route.path.split('/')[1]
      if (isParentPath) this.parentNav = route
      route.children = route.children?.filter(child => child.show)
      const isMainNavPath =
        this.$route.path === route.path ||
        route.children?.find(child => `${route.path}/${child.path}` === this.$route.path)
      if (isMainNavPath) this.activeChildren = route.children || []
      if (route.path === '/dome' && !MemberInfoGateway.isAllowFraud()) return false
      return route.show && route.type === 'nav'
    })
    this.footerNav = Routes.find(route => route.type === 'footer')
  }

  @Watch('elMainScrollTop')
  watchScroll() {
    const headerScrollDownSize = 105
    const headerScrollUpSize = 95
    if (this.elMainScrollTop >= headerScrollDownSize) this.isScrolled = true
    else if (this.elMainScrollTop < headerScrollUpSize) this.isScrolled = false
  }

  handleScroll() {
    this.elMainScrollTop = this.$el.getElementsByClassName('el-main')[0].scrollTop
  }
}
