



















































































































































































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import FormItemsOption from '@/models/forms/FormItemsOption'

@Component
export default class FormItems extends Vue {
  @Prop(Object) formField!: Dictionary<any>
  @Prop(Array) formItems!: Array<FormItemsOption>
  @Prop(String) innerClass!: string
  @Prop(String) outerClass!: string
  @Prop({
    type: Boolean,
    required: false,
    default: false
  }) useFormItemContainer!: boolean
  @Prop({
    type: Boolean,
    required: false,
    default: false
  }) hideLabel!: boolean

  get getFormItems () {
    return this.formItems.filter(item => !item.hide)
  }

  get dateRangeShortCuts () {
    const shortcuts: Array<Dictionary<any>> = [{
      text: 'Last week',
      onClick (picker: any) {
        const end = new Date()
        const start = new Date()
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
        picker.$emit('pick', [start, end])
      }
    }, {
      text: 'Last month',
      onClick (picker: any) {
        const end = new Date()
        const start = new Date()
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
        picker.$emit('pick', [start, end])
      }
    }]
    return shortcuts
  }

  isRadioGroupType (form: FormItemsOption): boolean {
    return form.type.includes('radio') && !!form.options?.length
  }

  getFormItemProp (form: FormItemsOption): string | undefined {
    if (!this.useFormItemContainer) return
    return form.prop
  }

  getFormItemLabel (form: FormItemsOption): string | undefined {
    if (!this.useFormItemContainer || form.useFormItemContainer === false) return
    return form.label
  }

  getValue (form: FormItemsOption): any {
    if (!this.useFormItemContainer || !this.formField) return form.value
    const splitProp = form.prop.split('.')
    const isNestedProp = splitProp.length > 1 && typeof this.formField[splitProp[0]] === 'object'
    let value
    if (isNestedProp) value = this.formField[splitProp[0]][splitProp[1]]
    else value = this.formField[form.prop]
    if (value !== undefined && typeof value !== 'boolean' && typeof value !== 'object') value = value.toString()
    return value
  }

  getImgUrl (imgSrc: string) {
    return require('@/assets/images/' + imgSrc)
  }

  setValue (value: any, form: FormItemsOption) {
    if (!this.useFormItemContainer || !this.formField) {
      form.value = value
      return
    }
    const splitProp = form.prop.split('.')
    const isNestedProp = splitProp.length > 1 && typeof this.formField[splitProp[0]] === 'object'
    if (isNestedProp) this.formField[splitProp[0]][splitProp[1]] = value
    else this.formField[form.prop] = value
  }

  onRadioButtonChange (form: FormItemsOption) {
    if (!form.emitHandlerName) return
    this.$emit(form.emitHandlerName, form.value)
  }

  onEmitAction (emitName: any, value: any) {
    if (!emitName) return
    this.$emit(emitName, value)
  }
}
