



























import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import FormItems from '@/components/shared/FormItems.vue'
import FormItemsOption from '@/models/forms/FormItemsOption'

@Component({
  components: { FormItems }
})
export default class SearchModal extends Vue {
  @Prop(Object) commentData!: { remittance_id?: string, recipient_id?: string, comment?: string }
  field: Dictionary<any> = {
    comment: this.commentData.comment
  }
  commentOptions: Array<FormItemsOption> = []
  commentOption: FormItemsOption = {
    prop: 'comment',
    type: 'textarea',
    maxlength: 20,
    showLimit: true,
    value: undefined
  }

  async formValidate () {
    try {
      return await (this.$refs.commentForm as Vue & { validate: () => boolean | any }).validate()
    } catch (error) {
      return error
    }
  }

  async onSubmit () {
    const isValidate: boolean = await this.formValidate()
    if (!isValidate) return
    this.$emit('submit', Object.assign({}, this.commentData, this.field))
  }

  created () {
    const recipientNo = `${this.$t('commons.recipient_number')} : ${this.commentData.recipient_id}`
    const remittanceNo = `${this.$t('commons.transfer_id')} : ${this.commentData.remittance_id}`
    this.commentOption.label = `${recipientNo} / ${remittanceNo}`
    this.commentOptions.push(this.commentOption)
  }
}
