var render = function () {
var _obj, _obj$1;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.swiperInfos.outerClass},[_c('swiper',{class:_vm.swiperInfos.swiperClass,attrs:{"options":_vm.swiperInfos.swiperOptions}},_vm._l((_vm.swiperInfos.swiperElements),function(element){
var _obj, _obj$1, _obj$2;
return _c('swiper-slide',{key:element.underlineClass},[(_vm.swiperInfos.type === 'normal')?_c('div',{staticClass:"swiper-info-wrapper"},[(element.underlineClass === 'fx' || element.underlineClass === 'singapore' || (element.isMultipleLines && _vm.isEnglish))?_c('div',{staticClass:"element-title-wrapper"},[_c('h2',{class:( _obj = {}, _obj[_vm.swiperInfos.titleClass] = true, _obj['multiple-lines'] =  true, _obj ),domProps:{"innerHTML":_vm._s(element.titleMobile)}}),_c('hr',{class:( _obj$1 = {'under-multiple-line': true}, _obj$1[element.underlineClass] = true, _obj$1 )})]):_c('div',{staticClass:"element-title-wrapper"},[_c('h2',{class:( _obj$2 = {}, _obj$2[_vm.swiperInfos.titleClass] = true, _obj$2['single-line'] =  true, _obj$2 )},[_vm._v(" "+_vm._s(element.title)+" ")])]),_c('p',{class:_vm.swiperInfos.contentClass},[_vm._v(" "+_vm._s(_vm.removeBr(element.content))+" ")])]):(_vm.swiperInfos.type === 'reviewCard')?[_c('ReviewCard',{attrs:{"review-summary":element.title,"review-summary-mobile":element.titleMobile,"review-content":element.content,"review-reviewer":element.reviewer}})]:_vm._e()],2)}),1),(_vm.swiperInfos.swiperOptions.navigation)?_c('span',[_c('img',{class:( _obj = {
        'prev-icon': true
      }, _obj[_vm.swiperInfos.swiperBtnClass] = true, _obj ),attrs:{"src":require("@/assets/images/left-arrow.svg"),"alt":"left"}}),_c('img',{class:( _obj$1 = {
        'next-icon': true
      }, _obj$1[_vm.swiperInfos.swiperBtnClass] = true, _obj$1 ),attrs:{"src":require("@/assets/images/right-arrow.svg"),"alt":"right"}})]):_vm._e(),(_vm.swiperInfos.swiperOptions.pagination)?_c('div',{class:_vm.swiperInfos.paginationClass,attrs:{"slot":"pagination"},slot:"pagination"}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }