




















































































import Vue from 'vue'
import Component from 'vue-class-component'
import { AddressModalPresenter } from '@/presentation/address/AddressModalPresenter'
import FormItems from '@/components/shared/FormItems.vue'
import { Prop } from 'vue-property-decorator'
import { Address } from '@/presentation/address/AddressField'

interface ValidationObject {
  line1?: any[]
  line2?: any[]
  line1Detail?: any[]
}

@Component({
  components: { FormItems }
})
export default class AddressModal extends Vue {
  @Prop(Object) address!: Address

  addressPresenter: AddressModalPresenter = new AddressModalPresenter(this.address)
  isManualInputOpen = false

  onClickManualAddress() {
    this.isManualInputOpen = true
  }

  onSelectManualCountry() {
    this.addressPresenter.onSelectManualCountry()
  }

  async onSubmitManualAddress() {
    const isValid: boolean = await this.addressPresenter.onSubmitManual(this.$refs.manualFormField)
    if (!isValid) return
    this.isManualInputOpen = false
    this.$emit('onSelectedAddress', this.addressPresenter.address)
  }

  async onSubmit() {
    const isValid: boolean | ValidationObject = await this.addressPresenter.onSubmit(
      this.$refs[this.addressPresenter.formName]
    )

    if (!isValid || Object.keys(isValid).length >= 1) return

    this.$emit('onSelectedAddress', this.addressPresenter.address)
  }
}
