




















import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { UpdateCorpMemberLocaleParam } from '@/gateway/corpMember/UpdateCorpMemberLocale'
import {
  ICorpMemberLocalePresentation,
  CorpMemberLocalePresentation
} from '@/presentation/user/CorpMemberLocalePresentation'
import MemberInfo from '@/models/MemberInfo'
import i18n from '@/plugins/i18n'
import { MemberInfoGateway } from '@/gateway/commons/MemberInfoGateway'

@Component
export default class LanguageButton extends Vue {
  @Prop(String) koreanString!: string
  @Prop(String) englishString!: string
  @Prop(String) layoutLocation!: 'footer' | 'sign'

  isEnglish: boolean = i18n.locale.includes('en')
  corpMemberLocalePresentation: ICorpMemberLocalePresentation = new CorpMemberLocalePresentation()

  get isFooterType (): boolean {
    return this.layoutLocation === 'footer'
  }

  onClickLanguage (language: string): void {
    try {
      const memberInfo: MemberInfo = MemberInfoGateway.getMemberInfo()
      this.updateMemberLocale({ id: memberInfo.member_id, locale: language.toUpperCase() })
    } catch (error) {}
    localStorage.setItem('locale', language)
    location.reload()
  }

  async updateMemberLocale (data: UpdateCorpMemberLocaleParam): Promise<void> {
    this.corpMemberLocalePresentation.updateCorpMemberLocale(data)
  }

  isStringKorean (): boolean {
    return this.koreanString === '한국어'
  }
}
