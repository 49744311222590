








































import Vue from 'vue'
import Component from 'vue-class-component'
import MainHeader from '@/components/commons/MainHeader.vue'
import ContentWithSwiper from '@/components/commons/ContentWithSwiper.vue'
import MainTitle from '@/components/landing/MainTitle.vue'
import AskRegister from '@/components/landing/AskRegister.vue'
import ReviewSection from '@/views/landing/ReviewSection.vue'
import MainFooter from '@/components/commons/MainFooter.vue'
import PartneringCompanies from '@/components/landing/PartneringCompanies.vue'
import BasicSwiperOptions from '@/models/BasicSwiperOptions'

@Component({
  components: {
    MainHeader,
    ContentWithSwiper,
    ReviewSection,
    MainTitle,
    AskRegister,
    PartneringCompanies,
    MainFooter
  }
})
export default class LandingMain extends Vue {
  presentInfos: BasicSwiperOptions = {
    type: 'normal',
    outerClass: 'presents-contents-mobile',
    paginationClass: 'presents-pagination',
    swiperClass: 'presents-swiper-container',
    swiperBtnClass: 'swiper-btn',
    titleClass: 'presents-elements-title',
    contentClass: 'presents-elements-content',
    swiperOptions: {
      navigation: {
        nextEl: '.next-icon',
        prevEl: '.prev-icon'
      },
      pagination: {
        el: '.presents-pagination',
        clickable: true
      },
      loop: true,
      autoplay: {
        delay: 4000,
        stopOnLastSlide: false
      },
      speed: 1000
    }
  }
  presentTopInfos: Array<Object> = []
  presentBottomInfos: Array<Object> = []

  introductionInfos: BasicSwiperOptions = {
    type: 'normal',
    swiperClass: 'introduction-swiper-container',
    outerClass: 'introduction-mobile',
    paginationClass: 'introduction-pagination',
    swiperBtnClass: 'swiper-btn',
    titleClass: 'introduction-elements-title',
    contentClass: 'introduction-elements-content',
    swiperOptions: {
      navigation: {
        nextEl: '.next-icon',
        prevEl: '.prev-icon'
      },
      pagination: {
        el: '.introduction-pagination',
        clickable: true
      },
      loop: true,
      autoplay: {
        delay: 4000,
        stopOnLastSlide: false
      },
      speed: 1000
    }
  };

  get isEnglish (): boolean {
    return localStorage.getItem('locale') === 'en'
  }

  get inquiryButtonTextClass (): string {
    return this.isEnglish ? 'double-line' : 'single-line'
  }

  scrollTo (target: string) {
    this.$el
      .querySelector(target)
      ?.scrollIntoView({ block: 'start', behavior: 'smooth' })
  }

  setPresentElements () {
    this.presentInfos.swiperElements = [{
      title: `${this.$t('landing.service.fee_title')}`,
      content: `${this.$t('landing.service.fee_content')}`
    }, {
      title: `${this.$t('landing.service.hedge_title')}`,
      content: `${this.$t('landing.service.hedge_content')}`
    }, {
      title: `${this.$t('landing.service.easy_title')}`,
      content: `${this.$t('landing.service.easy_content')}`
    }, {
      title: `${this.$t('landing.service.quick_title')}`,
      content: `${this.$t('landing.service.quick_content')}`
    }, {
      title: `${this.$t('landing.service.safe_title')}`,
      content: `${this.$t('landing.service.safe_content')}`
    }]
    this.presentInfos.swiperElements.forEach((element, index) => {
      if (index < 3) this.presentTopInfos.push(element)
      else this.presentBottomInfos.push(element)
    })
  }

  setIntroductionElements () {
    this.introductionInfos.swiperElements = [
      {
        title: 'Global Cross - border FX Solution',
        titleMobile: 'Global Cross - border<br>FX Solution',
        content: `${this.$t('landing.company.fintech_content')}`,
        underlineClass: 'fx',
        isMultipleLines: true
      },
      {
        title: `${this.$t('landing.company.singapore_title')}`,
        titleMobile: `${this.$t('landing.company.singapore_title_mobile')}`,
        content: `${this.$t('landing.company.singapore_content')}`,
        underlineClass: 'singapore',
        isMultipleLines: true
      },
      {
        title: `${this.$t('landing.company.aml_title')}`,
        titleMobile: `${this.$t('landing.company.aml_title_mobile')}`,
        content: `${this.$t('landing.company.aml_content')}`,
        underlineClass: 'aml',
        isMultipleLines: true
      },
      {
        title: `${this.$t('landing.company.security_title')}`,
        titleMobile: `${this.$t('landing.company.security_title_mobile')}`,
        content: `${this.$t('landing.company.security_content')}`,
        underlineClass: 'security',
        isMultipleLines: true
      }
    ]
  }

  created () {
    this.setPresentElements()
    this.setIntroductionElements()
  }
}
