
































import Vue from 'vue'
import Component from 'vue-class-component'
import FormItems from '@/components/shared/FormItems.vue'
import { AffiliatedSignUp } from '@/presentation/affiliate/AffiliatedSignUp'

@Component({
  components: { FormItems }
})
export default class SignUp extends Vue {
  private login: AffiliatedSignUp = new AffiliatedSignUp()

  async onSubmit () {
    const isValid = await this.login.onSubmit(this.$refs[this.login.formName])
    if (isValid) {
      this.$message({ message: '로그인에 성공하였습니다. ', type: 'success' })
      await this.$router.push('affiliate/documents')
    } else {
      this.$message({ message: '로그인에 실패하였습니다. 로그인 정보를 다시 확인 해 주세요.', type: 'error' })
    }
  }
}
