
























































































import Vue from 'vue'
import Component from 'vue-class-component'
import FormItems from '@/components/shared/FormItems.vue'
import $eventBus from '@/plugins/eventBus'
import { BusinessOwnerInfo } from '@/presentation/affiliate/BusinessOwnerInfo'
import { Prop } from 'vue-property-decorator'

@Component({
  components: { FormItems }
})
export default class BusinessOwnerDocument extends Vue {
  @Prop(String) docName!: string

  document: BusinessOwnerInfo = new BusinessOwnerInfo()

  get isCeoInfoExist () {
    return this.document.ceoInfoList && this.document.ceoInfoList.length
  }

  checkDocumentValidate (docName: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      $eventBus.$emit(`${docName}InfoValidate`, resolve, reject)
    }).then(async () => {
      return true
    }).catch((docName) => {
      this.$alert('필수 항목을 모두 입력해 주세요.', {
        showCancelButton: true,
        showConfirmButton: false,
        dangerouslyUseHTMLString: true,
        cancelButtonText: '닫기',
        center: true
      })
      this.$emit('moveTab', docName)
      return false
    })
  }

  async onSubmit (): Promise<void> {
    const companyValid = await this.checkDocumentValidate('company')
    if (!companyValid) return
    const leaderAccountValid = await this.checkDocumentValidate('leaderAccount')
    if (!leaderAccountValid) return
    const isValid = await this.document.formValidator.validate(this.$refs[this.document.formName])
    if (!isValid) return
    await this.document.onSubmit()
    this.$emit('onSubmitted', this.docName)
  }

  created () {
    if (!this.document.isEnable()) return
    this.$emit('setDocumentEnable', this.docName)
    if (this.document.isActivate()) this.$emit('moveTab', this.docName)
  }
}
