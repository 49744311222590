















import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { partneringCompaniesImgs } from '@/data/PartneringCompaniesImgs'

@Component
export default class PartneringCompanies extends Vue {
  partneringCompaniesImgs: Array<string> = partneringCompaniesImgs

  getImgUrl(name: string) {
    return require('@/assets/images/partners/' + name + '.png')
  }
}
