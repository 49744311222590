




























































































import { Vue, Component, Watch } from 'vue-property-decorator'
import History from '@/components/shared/TransferHistory.vue'
import Details from '@/components/shared/TransferHistoryDetails.vue'
import dayjs from 'dayjs'
import { ITransferHistoryViewPresentation, TransferHistoryViewPresentation } from '@/presentation/transferHIstory/TransferHistoryViewPresentation'
import { RemittanceHistoryGroup } from '@/models/remittance'

interface Period {
  from: string
  to: string
}

interface Time {
  readonly start: string
  readonly end: string
}

@Component({
  components: {
    History,
    Details
  }
})
export default class TransferHistory extends Vue {
  transferHistoryPresentation: ITransferHistoryViewPresentation = new TransferHistoryViewPresentation()

  period: Period = {
    from: '',
    to: ''
  } as Period
  time: Time = {
    start: 'T00:00:00+09:00',
    end: 'T23:59:59+09:00'
  } as Time
  page: number = 1
  pickerOptions: Object = {
    disabledDate (time: Date) {
      return time.getTime() > Date.now()
    }
  }
  isReadyDetails: boolean = true
  showDialog: boolean = false

  get shownDetails (): string | (string | null)[] {
    return this.$route.query.details
  }

  get title (): string {
    return this.shownDetails ? `${this.$t('menu.transfer_history_more')}` : ''
  }

  get filteredList (): Array<RemittanceHistoryGroup> {
    if (!this.shownDetails) return this.transferHistoryPresentation.dataResources.list.list
    return this.transferHistoryPresentation.filterList(this.shownDetails as string)
  }

  get usableHistoryCertificate (): boolean {
    const invalidStatus = !(this.shownDetails && this.filteredList[0])
    if (invalidStatus) return false
    const REMITTANCE_DONE = 2
    return this.filteredList[0].group_status === REMITTANCE_DONE
  }

  get getPeriod (): Object {
    const { from, to } = this.$route.query
    if (this.showDialog) return this.period
    if (this.$route.query.from) return { from, to }
    return {}
  }

  get getPage (): number {
    return this.showDialog ? this.page : (+this.$route.query.page || 1)
  }

  @Watch('$route.query', { immediate: true })
  setDataResource (): void {
    this.setListData({})
    this.setDetailsData()
  }

  async setDetailsData (): Promise<any> {
    if (!this.shownDetails) return
    const remittanceGroupId = this.shownDetails as string
    await this.transferHistoryPresentation.setDetailsData(remittanceGroupId)
    this.isReadyDetails = false
  }

  async setListData ({ newParams = {}, field = 'list' }): Promise<void> {
    this.transferHistoryPresentation.setListData({ newParams, field, page: this.getPage, period: this.getPeriod })
    field === 'details' && (this.isReadyDetails = false)
  }

  changePage (page: number): void {
    if (this.showDialog) {
      this.page = page
      this.setListData({ newParams: this.getPeriod, field: 'listByPeriod' })
    } else {
      const { path } = this.$route
      let query = { page: `${page}` }
      query = Object.assign(query, this.getPeriod)
      this.$router.push({ path, query })
    }
  }

  get startCreatedAt (): string {
    const SEARCHABLE_MONTHS_RANGE = 3
    return dayjs(this.transferHistoryPresentation.dataResources.list.list[0].created_at)
      .subtract(SEARCHABLE_MONTHS_RANGE, 'month')
      .format(`YYYY-MM-DD${this.time.start}`)
  }

  get recentCreatedAt (): string {
    return dayjs(this.transferHistoryPresentation.dataResources.list.list[0].created_at).format(`YYYY-MM-DD${this.time.end}`)
  }

  setPeriod (newPeriod: Period) {
    this.page = 1
    this.period = Object.assign(this.period, newPeriod)
    this.setListData({ newParams: this.period, field: 'listByPeriod' })
  }

  initDefaultPeriod (): void {
    const emptyDate = !this.period.to
    const period = { from: this.startCreatedAt, to: this.recentCreatedAt }
    emptyDate && this.setPeriod(period)
  }

  setStartDate (value: string): void {
    const period = Object.assign(this.period, { from: `${value}${this.time.start}` })
    const lessThanEndDate = new Date(value).getTime() > new Date(this.period.to).getTime()
    lessThanEndDate && (period.to = `${value}${this.time.end}`)
    this.setPeriod(period)
  }

  setEndDate (value: string): void {
    const period = Object.assign(this.period, { to: `${value}${this.time.end}` })
    const lessThanStartDate = new Date(value).getTime() < new Date(this.period.from).getTime()
    lessThanStartDate && (period.from = `${value}${this.time.start}`)
    this.setPeriod(period)
  }
}
