






















import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component
export default class BasicDialog extends Vue {
  @Prop(String) title!: string
  @Prop(String) innerClass!: string
  @Prop(String) content!: string
  @Prop(Boolean) useDivider!: boolean
  @Prop(String) size!: undefined | null | 'small' | 'mini'
  @Prop(Boolean) closeOnBackground!: boolean
  @Prop(String) iconUrl!: string
  @Prop(String) dialogTitle!: string

  visible: boolean = true

  get imgUrl(): string {
    return require('@/assets/images/' + this.iconUrl)
  }
}
