













































































import Vue from 'vue'
import Component from 'vue-class-component'
import CorpSnapshot from '@/components/home/CorpSnapshot.vue'
import { HomeMainPresentation, IHomeMainPresentation } from '@/presentation/home/HomeMainPresentation'
import RemittanceChart from '@/components/home/RemittanceChart.vue'
import { DateFilterType } from '@/gateway/dashboard/GetRemittanceSummarySeries'

@Component({
  components: { RemittanceChart, CorpSnapshot }
})
export default class HomeMain extends Vue {
  homeMainPresentation: IHomeMainPresentation = new HomeMainPresentation()

  corpName: string = ''
  snapshotIsLoaded: boolean = false
  snapshotChartIsLoaded: boolean = false
  datePickerType: 'daterange' | 'monthrange' = 'daterange'
  dateFilterTypeOptions: Array<Dictionary<string | number>> = [
    {
      label: this.$t('home.transfer_graph_filter_daily') as string,
      value: DateFilterType.DAY
    },
    {
      label: this.$t('home.transfer_graph_filter_transferdate') as string,
      value: DateFilterType.TRANSFER_DATE
    },
    {
      label: this.$t('home.transfer_graph_filter_monthly') as string,
      value: DateFilterType.MONTH
    }
  ]

  get hasSnapshots (): boolean {
    return !(this.snapshotIsLoaded && !this.homeMainPresentation.hasRemittanceCount)
  }

  get emptyGuidance (): string {
    return this.$t('home.transfer_blank', { company_name: this.corpName }) as string
  }

  async onSelectDateType (value: DateFilterType) {
    const isMonthlyType = value === DateFilterType.MONTH
    const isDailyType = value === DateFilterType.DAY || value === DateFilterType.TRANSFER_DATE
    const isDailyTypePickerBefore = this.datePickerType === 'daterange'
    const isCommonTypeChanged = isDailyTypePickerBefore ? isMonthlyType : isDailyType
    if (isCommonTypeChanged && isMonthlyType) this.datePickerType = 'monthrange'
    if (isCommonTypeChanged && isDailyType) this.datePickerType = 'daterange'
    this.homeMainPresentation.dateFilterType = value
    await this.homeMainPresentation.setRemittanceSummaryChart()
  }

  async onSelectDateRange () {
    await this.homeMainPresentation.setRemittanceSummaryChart()
  };

  async created () {
    await this.homeMainPresentation.setSnapshotData()
    this.snapshotIsLoaded = true
    await this.homeMainPresentation.setRemittanceSummaryChart()
    this.snapshotChartIsLoaded = true
    this.corpName = await this.homeMainPresentation.getCorpName()
  }
}
