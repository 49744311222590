
































import { GetWalletActivities } from '@/gateway/wallet/GetWalletActivities'
import { WalletActivity } from '@/models/wallet/WalletActivity'
import { CurrencyEnum } from '@/enums/CurrencyEnum'
import { IWalletPresentation, WalletPresentation } from '@/presentation/wallet/WalletPresentation'
import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { Utils } from '@/static/Utils'
import { Getter, Mutation } from 'vuex-class'
import { MemberInfoGateway } from '@/gateway/commons/MemberInfoGateway'

@Component
export default class WalletMenuItem extends Vue {
  @Getter hasNewWalletActivityDot: any
  @Getter getCurrenciesAndBalance: any
  @Mutation setNewWalletActivityDot: any
  @Mutation setWalletCurrenciesAndBalance: any

  walletPresentation: IWalletPresentation = new WalletPresentation()
  newWalletTimer: number = 0
  walletBalanciesTimer: number = 0
  corpId: number = Number(MemberInfoGateway.getMemberInfo().corp_id)

  async setWalletHasNewActivity (): Promise<void> {
    const getWalletActivitiesResponse: Array<WalletActivity> = await GetWalletActivities.getInstance().request({ corp_id: this.corpId })
    if (getWalletActivitiesResponse.length > 0) {
      const newResponseTimestamp = Date.parse(`${getWalletActivitiesResponse[0].transaction_time}`)
      this.setNewWalletActivityDot(newResponseTimestamp)
    }
  }

  get hideDot (): boolean {
    return !this.hasNewWalletActivityDot
  }

  get currenciesAndBalance (): Array<{ currency: keyof typeof CurrencyEnum, balance: string }> {
    return this.getCurrenciesAndBalance!
  }

  get walletPageClass (): string {
    const underLineClass = 'el-divider el-divider--horizontal wallet-underline'
    return this.$route.path.startsWith('/wallet') ? `${underLineClass} is-active` : underLineClass
  }

  getWalletBalanceClass (balance: string) {
    const smallLetter = balance.length > 14 ? ' letter-small' : ''
    return `wallet-balance${smallLetter}`
  }

  getImgUrl (currency: keyof typeof CurrencyEnum) {
    const country = Utils.currencyToCountry(currency)
    return require(`@/assets/images/country/${country.toLowerCase()}.svg`)
  }

  startNewWalletTimer () {
    clearTimeout(this.newWalletTimer)
    this.newWalletTimer = setTimeout(async () => {
      await this.setWalletHasNewActivity()
      this.startNewWalletTimer()
    }, 1000 * 60 * 15)
  }

  startWalletBalanciesTimer () {
    clearTimeout(this.walletBalanciesTimer)
    this.walletBalanciesTimer = setTimeout(() => {
      this.setWalletCurrenciesAndBalance()
      this.startWalletBalanciesTimer()
    }, 1000 * 30)
  }

  destroyed () {
    clearTimeout(this.newWalletTimer)
    clearTimeout(this.walletBalanciesTimer)
  }

  async created () {
    this.setWalletCurrenciesAndBalance()
    await this.walletPresentation.initialize()
    await this.setWalletHasNewActivity()
    this.startNewWalletTimer()
    this.startWalletBalanciesTimer()
  }
}
