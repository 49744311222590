






























import Vue from 'vue'
import Component from 'vue-class-component'
import CompanyDocument from '@/components/affiliate/CompanyDocument.vue'
import LeaderAccountDocument from '@/components/affiliate/LeaderAccountDocument.vue'
import OwnerDocument from '@/components/affiliate/OwnerDocument.vue'
import BusinessOwnerDocument from '@/components/affiliate/BusinessOwnerDocument.vue'
import MemberInfo from '@/models/MemberInfo'
import { Getter, Mutation } from 'vuex-class'
import { CorpAll, CorpStatus } from '@/gateway/affiliate/model/CorpAll'
import { GetCorpAll } from '@/gateway/affiliate/GetCorpAll'
import { MemberInfoGateway } from '@/gateway/commons/MemberInfoGateway'

@Component({
  components: { BusinessOwnerDocument, OwnerDocument, CompanyDocument, LeaderAccountDocument }
})
export default class Documents extends Vue {
  @Getter getCorpAllState: any
  @Mutation setCorpAllState: any

  memberInfo: MemberInfo | undefined

  selectedDocument = 'company'
  documentsLabel: Dictionary<string> = {}

  documentsComponent: Dictionary<string> = {
    company: 'CompanyDocument',
    leaderAccount: 'LeaderAccountDocument'
  }

  documentsDisabled: Dictionary<boolean> = {
    company: false,
    leaderAccount: true,
    owner: true
  }

  onSelectedBusinessCategory (isCorporateType: boolean): void {
    const ownerName = isCorporateType ? '법인' : '개인'
    this.documentsLabel = Object.assign({}, this.documentsLabel, { owner: `사업체 실제소유자 정보 (${ownerName})` })
    const ownerComponentName = isCorporateType ? 'BusinessOwnerDocument' : 'OwnerDocument'
    this.documentsComponent = Object.assign({}, this.documentsComponent, { owner: ownerComponentName })
  }

  setDocumentEnable (docName: string, disabled: boolean = false) {
    this.documentsDisabled[docName] = disabled
    this.documentsDisabled = Object.assign({}, this.documentsDisabled)
  }

  onSubmitted (docName: string) {
    if (docName === 'owner') return this.$router.push('download')
    let nextDocName: string = this.selectedDocument
    if (docName === 'company') nextDocName = 'leaderAccount'
    else if (docName === 'leaderAccount') nextDocName = 'owner'
    this.setDocumentEnable(nextDocName)
    this.selectedDocument = nextDocName
  }

  async setCorpAll (): Promise<void> {
    let corpAll: CorpAll = this.getCorpAllState
    const hasCorpAllValue: boolean = !!Object.keys(corpAll).length
    if (hasCorpAllValue) return
    if (!this.memberInfo) return
    const corpId = this.memberInfo.corp_id
    corpAll = await GetCorpAll.getInstance().request({ corp_id: corpId })
    if (corpAll.status === Number(CorpStatus.PROCESSING)) await this.$router.replace('account')
    if (corpAll.status === Number(CorpStatus.FORM_COMPLETE)) await this.$router.replace('upload')
    this.setCorpAllState(corpAll)
  }

  moveTab (docName: string) {
    this.selectedDocument = docName
  }

  setDocumentsTabs () {
    this.documentsLabel = Object.assign({}, {
      company: '사업체 정보',
      leaderAccount: '대표자 및 계좌 정보'
    })
  }

  async created (): Promise<void> {
    if (!MemberInfoGateway.hasAuthInfo()) {
      await this.$router.push('/signUp')
      return
    }
    this.memberInfo = MemberInfoGateway.getMemberInfo()
    const isBizTempAudience = this.memberInfo.aud === 'biz_temp'
    if (!isBizTempAudience) {
      await this.$router.push('/signIn')
      return
    }
    await this.setCorpAll()
    this.setDocumentsTabs()
  }
}
