








export default {
    props: {
        useTitle: {
            type: Boolean,
            default: true,
        }
    }
}
