
























































































































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import BasicSwiper from '@/components/commons/BasicSwiper.vue'
import BasicSwiperOptions from '@/models/BasicSwiperOptions'

@Component({
  components: {
    BasicSwiper
  }
})
export default class ContentWithSwiper extends Vue {
  @Prop() componentType!: String
  @Prop() contentInfos!: BasicSwiperOptions
  @Prop() presentTopInfos!: Array<Object>
  @Prop() presentBottomInfos!: Array<Object>

  startOfSentbiz: number = 0

  get titleWithoutBi (): string {
    return `${this.$t('landing.service.title')}`.replace(/SENTBIZ/g, '').replace(/\?/g, '')
  }

  created () {
    this.startOfSentbiz = `${this.$t('landing.service.title')}`.indexOf('SENTBIZ')
  }
}
