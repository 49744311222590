


































import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'

@Component
export default class Reviewcard extends Vue {
  @Prop() reviewSummary!: string
  @Prop() reviewSummaryMobile!: string
  @Prop() reviewContent!: string
  @Prop() reviewReviewer!: string

  removeBr (target: string): string {
    return target.replace(/<br>/g, '')
  }
}
