




































































import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator'
import { RegisterInquiry, RegisterInquiryParam } from '@/gateway/notify/RegisterInquiry'
import FormItemsOption from '@/models/forms/FormItemsOption'
import FormItemsModelInput from '@/components/shared/FormItemsModelInput.vue'

interface RegisterInquiryFormField extends RegisterInquiryParam {
  title: string
  remittance_purpose: string
  sign_up_path: string
  license_number: string
  receive_country: string
  receive_currency: string
  remittance_volume: string
}

@Component({
  components: { FormItemsModelInput }
})
export default class AskRegister extends Vue {
  approved: boolean = false
  submitDisabled: boolean = true
  moreExpanded: boolean = false

  formName: string = 'inquiryForm'
  formField: RegisterInquiryFormField | null = {
    corp_name: '',
    name: '',
    email: '',
    phone_number: '',
    content: '',
    title: '',
    remittance_purpose: '',
    sign_up_path: '',
    license_number: '',
    receive_country: '',
    receive_currency: '',
    remittance_volume: ''
  }

  get isEveryFieldInserted(): boolean {
    if (!this.formField) return false
    return !!(
      this.formField.corp_name &&
      this.formField.name &&
      this.formField.email &&
      this.formField.phone_number &&
      this.formField.remittance_purpose &&
      this.formField.sign_up_path
    )
  }

  get moreByLanguage(): string {
    return localStorage.getItem('locale') === 'ko' ? '내용보기+' : 'more+'
  }

  formOptions: Array<FormItemsOption> = [
    {
      prop: 'corp_name',
      model: 'corp_name',
      type: 'text',
      placeHolder: this.$t('commons.business_name') as string,
      formItemClass: 'former-forms-in-flex forms-in-flex full-width-item'
    },
    {
      prop: 'title',
      model: 'title',
      type: 'text',
      placeHolder: this.$t('commons.title') as string
    },
    {
      prop: 'name',
      model: 'name',
      type: 'text',
      placeHolder: this.$t('commons.name') as string
    },
    {
      prop: 'phone_number',
      model: 'phone_number',
      type: 'text',
      subType: 'number',
      placeHolder: this.$t('commons.phone_number') as string
    },
    {
      prop: 'email',
      model: 'email',
      type: 'text',
      subType: 'email',
      placeHolder: this.$t('commons.email') as string
    },
    {
      prop: 'remittance_purpose',
      model: 'remittance_purpose',
      type: 'select',
      options: [
        {
          label: this.$t('landing.contact.remittance_purpose.goods_services_payment') as string,
          value: this.$t('landing.contact.remittance_purpose.goods_services_payment') as string
        },
        {
          label: this.$t('landing.contact.remittance_purpose.capital_payment') as string,
          value: this.$t('landing.contact.remittance_purpose.capital_payment') as string
        },
        { label: this.$t('commons.etc') as string, value: this.$t('commons.etc') as string }
      ],
      placeHolder: this.$t('landing.contact.remittance_purpose_label') as string
    },
    {
      prop: 'sign_up_path',
      model: 'sign_up_path',
      type: 'select',
      options: [
        {
          label: this.$t('landing.contact.sign_up_path.google') as string,
          value: this.$t('landing.contact.sign_up_path.google') as string
        },
        {
          label: this.$t('landing.contact.sign_up_path.naver') as string,
          value: this.$t('landing.contact.sign_up_path.naver') as string
        },
        {
          label: this.$t('landing.contact.sign_up_path.other_portal') as string,
          value: this.$t('landing.contact.sign_up_path.other_portal') as string
        },
        {
          label: this.$t('landing.contact.sign_up_path.acquaintance') as string,
          value: this.$t('landing.contact.sign_up_path.acquaintance') as string
        },
        {
          label: this.$t('landing.contact.sign_up_path.customer_sentbe') as string,
          value: this.$t('landing.contact.sign_up_path.customer_sentbe') as string
        },
        { label: this.$t('commons.etc') as string, value: this.$t('commons.etc') as string }
      ],
      placeHolder: this.$t('landing.contact.sign_up_path_label') as string
    }
  ]

  latterFormOptions: Array<FormItemsOption> = [
    {
      prop: 'receive_country',
      model: 'receive_country',
      type: 'text',
      placeHolder: `${this.$t('landing.contact.receive_country')} (${this.$t('commons.optional')})`
    },
    {
      prop: 'receive_currency',
      model: 'receive_currency',
      type: 'text',
      placeHolder: `${this.$t('landing.contact.receive_currency')} (${this.$t('commons.optional')})`
    },
    {
      prop: 'license_number',
      model: 'license_number',
      type: 'text',
      subType: 'number',
      placeHolder: `${this.$t('landing.contact.license_number')} (${this.$t('commons.optional')})`,
      formItemClass: 'latter-forms-in-flex forms-in-flex full-width-item'
    },
    {
      prop: 'remittance_volume',
      model: 'remittance_volume',
      type: 'text',
      placeHolder: `${this.$t('landing.contact.remittance_volume')} (${this.$t('commons.optional')})`,
      formItemClass: 'latter-forms-in-flex forms-in-flex full-width-item'
    },
    {
      prop: 'content',
      model: 'content',
      type: 'textarea',
      formItemClass: 'latter-forms-in-flex forms-in-flex no-margin inquiry-text-area',
      rows: 4,
      resize: 'none',
      placeHolder: this.$t('landing.contact.inquire_optional') as string
    }
  ]

  checkSubmitDisabled() {
    if (!this.approved) {
      this.submitDisabled = true
      return
    }
    this.submitDisabled = !this.isEveryFieldInserted
  }

  @Watch('formField', { deep: true })
  isDisabledField() {
    this.checkSubmitDisabled()
  }

  async onSubmit() {
    if (!this.formField) return
    if (!this.isEveryFieldInserted) return
    const nameWithTitleField: RegisterInquiryParam = {
      corp_name: this.formField.corp_name,
      name: `${this.formField.name} / ${this.formField.title}`,
      phone_number: this.formField.phone_number,
      email: this.formField.email,
      content: this.formField.content,
      remittance_purpose: this.formField.remittance_purpose,
      sign_up_path: this.formField.sign_up_path,
      license_number: this.formField.license_number,
      receive_country: this.formField.receive_country,
      receive_currency: this.formField.receive_currency,
      remittance_volume: this.formField.remittance_volume
    }
    const response = await RegisterInquiry.getInstance().request(nameWithTitleField)
    const isSuccessResponse: boolean = response?.code === 200
    if (!isSuccessResponse) return
    this.$message.success(this.$t('landing.contact.complete_noti') as string)
  }
}
