












import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import ContentWithSwiper from '@/components/commons/ContentWithSwiper.vue'
import BasicSwiperOptions from '@/models/BasicSwiperOptions'

@Component({
  components: { ContentWithSwiper }
})
export default class ReviewSection extends Vue {
  reviewSwiperInfos: BasicSwiperOptions = {
    type: 'reviewCard',
    paginationClass: 'swiper-pagination',
    swiperClass: 'swiper-container',
    swiperOptions: {
      autoHeight: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      },
      loop: true,
      autoplay: {
        delay: 4000,
        stopOnLastSlide: false
      },
      speed: 1000
    }
  }

  created () {
    this.reviewSwiperInfos.swiperElements = [{
      title: `${this.$t('landing.review.1_title')}`,
      titleMobile: `${this.$t('landing.review.1_title_mobile')}`,
      content: `${this.$t('landing.review.1_content')}`,
      reviewer: `${this.$t('landing.review.1_from')}`
    }, {
      title: `${this.$t('landing.review.2_title')}`,
      titleMobile: `${this.$t('landing.review.2_title_mobile')}`,
      content: `${this.$t('landing.review.2_content')}`,
      reviewer: `${this.$t('landing.review.2_from')}`
    }, {
      title: `${this.$t('landing.review.3_title')}`,
      titleMobile: `${this.$t('landing.review.3_title_mobile')}`,
      content: `${this.$t('landing.review.3_content')}`,
      reviewer: `${this.$t('landing.review.3_from')}`
    }]
  }
}
