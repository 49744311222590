

















import Vue from 'vue'
import LoadingContainer from '@/components/commons/LoadingContainer.vue'
import { beforeLoginPaths } from '@/data/BeforeLoginPaths'
import { initializeStaticValues } from '@/static/StaticValues'
import { MemberInfoGateway } from '@/gateway/commons/MemberInfoGateway'
import MainHeader from '@/components/commons/MainHeader.vue'
import NaviMenu from '@/components/nav/NaviMenu.vue'
import SignInWrapper from '@/views/signIn/SignInWrapper.vue'
import AffiliatedWrapper from '@/views/affiliated/AffiliatedWrapper.vue'
import { SignOut } from '@/gateway/commons/SignOut'
import router from './router'
const FIVE_MINUTES: number = 300000;
export default Vue.extend({
  name: 'App',
  components: {
    AffiliatedWrapper,
    SignInWrapper,
    NaviMenu,
    MainHeader,
    LoadingContainer
  },
  data() {
    return {
      affiliatePath: 'affiliate',
      signInPaths: ['/signIn', '/password', '/signUp'],
      elMainScrollTop: 0,
      userActivityThrottlerTimeout: 0,
      userActivityMessageTimeout: 0,
      userActivityTimeout: 0,
      INACTIVE_USER_TIME_THRESHOLD: FIVE_MINUTES * 4,
      USER_ACTIVITY_THROTTLER_TIME: 1000
    }
  },
  computed: {
    isLanding(): boolean {
      return this.$route.path === '/'
    },
    isSignIn(): boolean {
      const routePath = this.$route.path
      return this.signInPaths.includes(routePath)
    },
    isNav(): boolean {
      const routePath = this.$route.path
      const parentPath = routePath.split('/')[1]
      return !beforeLoginPaths.includes(routePath) && this.affiliatePath !== parentPath
    },
    isAffiliatePath(): boolean {
      const routePath = this.$route.path
      const parentPath = routePath.split('/')[1]
      return this.affiliatePath === parentPath
    }
  },
  beforeMount(): void {
    this.activateActivityTracker()
  },
  async beforeCreate() {
    if (!MemberInfoGateway.hasAuthInfo()) return
    await initializeStaticValues()
  },
  beforeDestroy(): void {
    window.removeEventListener('mousemove', this.userActivityThrottler)
    window.removeEventListener('scroll', this.userActivityThrottler)
    window.removeEventListener('keydown', this.userActivityThrottler)
    window.removeEventListener('resize', this.userActivityThrottler)
    clearTimeout(this.userActivityTimeout)
    clearTimeout(this.userActivityThrottlerTimeout)
  },
  methods: {
    scrollTo(target: string) {
      this.$el.querySelector(target)?.scrollIntoView({ block: 'start', behavior: 'smooth' })
    },
    resetUserActivityTimeout(): void {
      clearTimeout(this.userActivityMessageTimeout)
      clearTimeout(this.userActivityTimeout)
      this.userActivityMessageTimeout = setTimeout(() => {
        this.$message({
          message: `${this.$t('notification.20_minutes_before_logout')}`,
          type: 'warning'
        })
        this.userActivityTimeout = setTimeout(async () => {
          const isDevEnv = process.env.NODE_ENV === 'development'
          if (isDevEnv) return
          const response: any = await new SignOut().request()
          if (response.code !== 200) {
            this.$message({
              message: `${this.$t('notification.errors.error')}`,
              type: 'error'
            })
            return
          }
          this.$alert(`${this.$t('notification.20_minutes_logout')}`)
          await router.push('/signIn')
        }, FIVE_MINUTES)
      }, this.INACTIVE_USER_TIME_THRESHOLD - FIVE_MINUTES)
    },
    userActivityThrottler(): void {
      if (!this.userActivityThrottlerTimeout && !this.isLanding && !this.isSignIn) {
        this.userActivityThrottlerTimeout = setTimeout(() => {
          this.resetUserActivityTimeout()
          clearTimeout(this.userActivityThrottlerTimeout)
          this.userActivityThrottlerTimeout = 0
        }, this.USER_ACTIVITY_THROTTLER_TIME)
      }
    },
    activateActivityTracker(): void {
      window.addEventListener('mousemove', this.userActivityThrottler)
      window.addEventListener('scroll', this.userActivityThrottler)
      window.addEventListener('keydown', this.userActivityThrottler)
      window.addEventListener('resize', this.userActivityThrottler)
    }
  },
  metaInfo: {
    meta: [{ charset: 'utf-8' }, { name: 'viewport', content: 'width=device-width, initial-scale=1, minimum-scale=1' }]
  }
})
