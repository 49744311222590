






































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component
export default class FAQ extends Vue {
  @Prop(String) isStandAlone!: boolean
  activeName: string = ''

  get title (): string {
    return this.isStandAlone ? this.$t('commons.faq') as string : ''
  }

  get wrapperClass (): string {
    return this.isStandAlone ? 'faq-area' : 'faq-area inner'
  }

  get faqArray () {
    const lokaliseFaqArray: Array<{ [key: number]: any}> = Object.entries(this.$t('faq'))
    delete lokaliseFaqArray[2][1].bank_account
    const exceptEtcCategories: Array<any> = lokaliseFaqArray.slice(1)
    const etcCategory = lokaliseFaqArray.shift()
    return [...exceptEtcCategories, etcCategory]
  }

  get titleArray () {
    return [
      `${this.$t('faq_category.signup')}`,
      `${this.$t('faq_category.transfer')}`,
      `${this.$t('faq_category.wallet')}`,
      `${this.$t('faq_category.etc')}`
    ]
  }

  handleClick (): void {
    this.activeName = ''
  }
}
