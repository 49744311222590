
























































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import FormRule from '@/models/forms/FormRule'
import ResponseCode from '@/enums/ResponseCode'
import { regexToPatternString } from '@/data/Regex'
import { initializeStaticValues } from '@/static/StaticValues'
import { SignApi } from '@/gateway/corpMember/SignApi'
import { ChangePasswordParam } from '@/gateway/corpMember/model/ChangePasswordParam'
import { ChangePassword } from '@/gateway/corpMember/ChangePassword'
import { OtpParam } from '@/gateway/corpMember/model/OtpParam'
import { CheckOtp } from '@/gateway/corpMember/CheckOtp'
import { SignApiParam } from '@/gateway/corpMember/model/SignApiParam'
import { MemberInfoGateway } from '@/gateway/commons/MemberInfoGateway'
import {
  CorpMemberLocalePresentation,
  ICorpMemberLocalePresentation
} from '@/presentation/user/CorpMemberLocalePresentation'
import { GetCorpInformation } from '@/gateway/commons/GetCorpInformation'
import { TranslateResult } from 'vue-i18n'

@Component
export default class OtpSignIn extends Vue {
  @Prop(Boolean) readonly isSignUp?: boolean
  @Prop(Boolean) readonly isSignIn?: boolean
  @Prop(Boolean) readonly isPasswordConfirm?: boolean

  authTimer = 0
  timeInterval = 0
  showOtpMessage = false
  isOtpRequested: boolean = false
  corpMemberId: string = ''

  formField: Dictionary<string> = {}

  corpMemberLocalePresentation: ICorpMemberLocalePresentation = new CorpMemberLocalePresentation()

  emailRule: FormRule = {
    required: true,
    pattern: regexToPatternString('email'),
    trigger: 'blur'
  }
  passwordRule: FormRule = {
    required: true,
    trigger: 'blur'
  }
  signUpPasswordRule: FormRule = {
    required: true,
    pattern: regexToPatternString('password'),
    trigger: 'blur'
  }
  passwordConfirmRule: FormRule = {
    validator: this.checkPasswordConfirm,
    trigger: 'blur'
  }
  otpRule: FormRule = {
    required: true,
    validator: this.checkTimer,
    trigger: 'blur'
  }

  signInRules: Dictionary<Array<FormRule>> = {
    email: [this.emailRule],
    password: [this.passwordRule]
  }
  otpRules: Dictionary<Array<FormRule>> = {
    otp: [this.otpRule]
  }
  passwordConfirmRules: Dictionary<Array<FormRule>> = {
    password: [this.passwordRule, this.signUpPasswordRule],
    passwordConfirm: [this.passwordRule, this.signUpPasswordRule, this.passwordConfirmRule]
  }

  signErrorMessages: { [T in ResponseCode]: TranslateResult } = {
    [ResponseCode.EXIST_EMAIL]: this.$t('notification.already_registered_login'),
    [ResponseCode.INVALID_LOGIN]: this.$t('notification.check_user_information'),
    [ResponseCode.NOT_FOUND_USER]: this.$t('notification.check_user_information'),
    [ResponseCode.PW_NOT_MATCH]: this.$t('notification.check_user_information'),
    [ResponseCode.OTP_TOKEN_NOTMATCH]: this.$t('notification.wrong_certification_number'),
    [ResponseCode.SMS_ERROR]: this.$t('notification.message_error_ask_cs'),
    [ResponseCode.PW_LOCK]: this.$t('notification.disabled_account'),
    [ResponseCode.PW_EXPIRE]: this.$t('notification.90_day_change_password'),
    [ResponseCode.PW_DUPLICATE]: this.$t('notification.4_before_password')
  }

  setRuleMessages() {
    this.emailRule.message = `${this.$t('commons.plz_type_email_partnership')}`
    this.passwordRule.message = `${this.$t('notification.password_required')}`
    this.signUpPasswordRule.message = `${this.$t('notification.mix_character_8_digits')}`
  }

  get getTimerCountdown(): string {
    const minutes: number = Math.floor(this.authTimer / 60)
    const seconds: string = `0${this.authTimer % 60}`.slice(-2)

    return `${minutes} : ${seconds}`
  }

  get getSubmitMethod(): () => void {
    if (this.isPasswordConfirm) return this.changePassword
    return this.isOtpRequested ? this.confirmOtp : this.requestSignInUp
  }

  get getSubmitLabel(): string {
    if (this.isPasswordConfirm) return `${this.$t('notification.registered')}`
    if (this.$route.path === '/password') return this.commonsLokalise('next')
    return this.commonsLokalise('login')
  }

  get getRules(): Dictionary<Array<FormRule>> {
    if (this.isPasswordConfirm) return this.passwordConfirmRules
    return this.isOtpRequested ? this.otpRules : this.signInRules
  }

  get showOtpForm(): boolean {
    return this.isOtpRequested && !this.isPasswordConfirm
  }

  commonsLokalise(prop: string): string {
    return this.$t(`commons.${prop}`) ? this.$t(`commons.${prop}`).toString() : ''
  }

  checkTimer(rule: any, value: any, callback: (error?: Error) => void): void {
    if (this.authTimer) {
      return callback()
    } else {
      return callback(new Error(`${this.$t('notification.time_expired')}`))
    }
  }

  checkPasswordConfirm(rule: any, value: any, callback: (error?: Error) => void): void {
    const isEqualPassword = this.formField.password && this.formField.password === this.formField.passwordConfirm
    if (isEqualPassword) {
      return callback()
    } else {
      return callback(new Error(`${this.$t('notification.password_mismatch')}`))
    }
  }

  setAuthorizeInLocalStorage(auth: string): void {
    MemberInfoGateway.setMemberInfo(this.formField.email, auth)
    if (!this.isSignUp) localStorage.setItem('locale', MemberInfoGateway.getMemberInfo().locale.toLowerCase())
  }

  setAuthenticateTimer(timer = 3 * 60): void {
    this.authTimer = timer
    clearInterval(this.timeInterval)
    this.timeInterval = setInterval(() => {
      --this.authTimer
      if (this.authTimer <= 0) {
        clearInterval(this.timeInterval)
      }
    }, 1000)
  }

  redirectPwChange() {
    this.$router.push('/password')
  }

  redirectSignIn() {
    this.$router.push('/signIn')
  }

  async formValidate(): Promise<any> {
    try {
      return await (this.$refs.signForm as Vue & { validate: () => boolean | any }).validate()
    } catch (error) {
      return error
    }
  }

  async requestSignInUp(): Promise<void> {
    let signInValidation: boolean = await this.formValidate()
    if (!signInValidation) return
    await this.requestOtp()
  }

  showErrorMessage(responseCode: ResponseCode): void {
    const message = this.signErrorMessages[responseCode] as string
    this.$message({ message, type: 'error' })
  }

  async requestOtp(): Promise<void> {
    const signApiParam: SignApiParam = {
      email: this.formField.email,
      password: this.formField.password,
      audience: this.isSignUp ? 'temporal_member' : 'member'
    }
    const signMethod = this.isSignUp ? SignApi.getInstance().signUp : SignApi.getInstance().signIn
    const response = await signMethod(signApiParam)
    const isSuccessResponse: boolean = response.code === 200 && response.data
    if (!isSuccessResponse) {
      const responseCode: ResponseCode = response.code
      this.showErrorMessage(responseCode)
      const isPwLocked = responseCode === ResponseCode.PW_LOCK
      if (isPwLocked) {
        const warning6Message = this.$t('notification.n_failed_login', { try_numbers: 6 })
        const lockMessage = this.signErrorMessages[ResponseCode.PW_LOCK]
        const alertMessage = `${warning6Message}<br>${lockMessage}`.replace(/\n/g, '<br>')
        await this.$alert(alertMessage, {
          center: true,
          dangerouslyUseHTMLString: true
        })
      }
      const isPwExpired = responseCode === ResponseCode.PW_EXPIRE
      if (isPwExpired) this.redirectPwChange()
      const isEmailExistAlready = responseCode === ResponseCode.EXIST_EMAIL
      if (isEmailExistAlready) this.redirectSignIn()
      return
    }
    MemberInfoGateway.removeMemberInfo()
    this.corpMemberId = this.isSignUp ? response.data?.member_id : response.data?.corp_member_id
    this.isOtpRequested = true
    const hasOtpForm: boolean = !!this.formField.otp
    if (!hasOtpForm) this.formField = Object.assign({}, this.formField, { otp: '' })
    this.$emit('otpRequested')
    this.setAuthenticateTimer()
    this.showOtpMessage = true
  }

  async confirmOtp(): Promise<void> {
    let signUpValidation: boolean = await this.formValidate()
    if (!signUpValidation) return
    const otpParam: OtpParam = {
      member_id: this.corpMemberId,
      token: this.formField.otp,
      audience: this.isSignUp ? 'temporal_member' : 'member'
    }
    const response = await CheckOtp.getInstance().request(otpParam)
    const isSuccessResponse: boolean = response.code === 200
    if (!isSuccessResponse) {
      this.showErrorMessage(response.code)
      return
    }
    const auth: string = response.data?.auth
    if (!auth) return
    GetCorpInformation.refresh()
    await this.setAuthorizeInLocalStorage(auth)
    this.$message({ message: `${this.$t('notification.certified')}`, type: 'success' })
    this.$emit('otpConfirmed')
  }

  async changePassword(): Promise<void> {
    let signUpValidation: boolean = await this.formValidate()
    if (!signUpValidation) return
    const locale = localStorage.getItem('locale') === null ? 'ko' : (localStorage.getItem('locale') as 'ko' | 'en')
    const changePasswordParam: ChangePasswordParam = {
      member_id: this.corpMemberId,
      password: this.formField.password,
      locale
    }
    const response = await ChangePassword.getInstance().request(changePasswordParam)
    const isSuccessResponse: boolean = response.code === 200
    if (!isSuccessResponse) {
      this.showErrorMessage(response.code)
      return
    }
    this.$message({ message: `${this.$t('notification.registered')}`, type: 'success' })
    await initializeStaticValues()
    this.corpMemberLocalePresentation.updateCorpMemberLocale({
      id: Number(this.corpMemberId),
      locale: localStorage.getItem('locale') === null ? 'ko' : (localStorage.getItem('locale') as string)
    })
    await this.$router.push('/home')
  }

  setFormField(): void {
    const emailField = { email: '' }
    const passwordField = { password: '' }
    this.formField = Object.assign(emailField)
    if (this.isSignIn) this.formField = Object.assign({}, emailField, passwordField)
    if (this.isPasswordConfirm) this.formField = Object.assign({}, passwordField, { passwordConfirm: '' })
  }

  created(): void {
    this.setFormField()
    this.setRuleMessages()
  }
}
